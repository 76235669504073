body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media print {
  @page {
    size: length;
    page-break-inside: always;
  }

  body * {
    visibility: hidden;
  }

  #printme,
  #printme * {
    visibility: visible;
    overflow: visible;
    page-break-inside: always;
  }
}

html {
  margin: 0;
  height: 100%;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.vh100 {
  height: 100vh;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgb(110, 110, 110) !important;
}